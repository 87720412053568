import React, { useEffect, useState } from "react";
import "./landing-title.css";

export default function LandingTitle() {
  const [display, setDisplay] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setDisplay(false);
    }, 7000);
  }, []);
  return (
    <div
      className="landing-title-container"
      style={{ display: display || "none" }}
    >
      <div className="landing-title">LOUIS-JACK</div>
    </div>
  );
}
