import React, { useState, useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import ReactPlayer from "react-player";
import ReactTimeout from "react-timeout";
import LandingTitle from "../components/LandingTitle";

import Layout from "../components/layout";
import SEO from "../components/seo";

import "../components/film-player.css";

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulLandingVideo {
        edges {
          node {
            landingVideo {
              file {
                url
              }
            }
          }
        }
      }
    }
  `);

  const [visible, setVisible] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);
  const [videoLoaded, setVideoLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, 4000);
  }, []);

  useEffect(() => {
    if (data) {
      const [videoData] = data.allContentfulLandingVideo.edges;
      setVideoUrl(videoData.node.landingVideo.file.url);
    }
  }, [data]);

  return (
    <>
      <LandingTitle />
      <div className={visible ? `landing-wrapper-visible` : "landing-wrapper"}>
        <Layout>
          <SEO title="Home" />
          {videoUrl && (
            <div className="video-container">
              <ReactPlayer
                width={"100%"}
                height={"100%"}
                url={videoUrl}
                loop={true}
                onReady={() => setVideoLoaded(true)}
                playing={videoUrl && videoLoaded}
                muted={true}
                playsinline={true}
                config={{ vimeo: { playerOptions: { playsinline: 1 } } }}
              />
            </div>
          )}
        </Layout>
      </div>
    </>
  );
};

export default ReactTimeout(IndexPage);
